import React from 'react';
import { Helmet } from 'react-helmet';
import icon from '../../favicon.ico';
import { createGlobalStyle } from 'styled-components';
import { cssVariables } from '../config';
import fontFiles from '../fonts';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'fira-sans';
    src: url(${fontFiles.firaSansRegular});
    format('woff');
  }
  @font-face {
    font-family: 'fira-sans';
    src: url(${fontFiles.firaSanLight});
    format('woff');
    font-weight: 100;
  }
  @font-face {
    font-family: 'fira-sans-medium';
    src: url(${fontFiles.firaSansMedium});
    format('woff');
  }
  @font-face {
    font-family: 'fira-sans';
    src: url(${fontFiles.firaSansBold});
    format('woff');
    font-weight: 700;
  }
  @font-face {
    font-family: 'fira-sans';
    src: url(${fontFiles.firaSansItalic});
    format('woff');
    font-style: italic;
  }
  @font-face {
    font-family: 'pacifico';
    src: url(${fontFiles.pacificoRegular});
    format('woff');
  }  

  body, html {
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    font-family: fira-sans, sans-serif;
    font-variant-ligatures: none;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    //object-fit: cover;
    //object-position: center;
    //font-family: 'object-fit: cover; object-position: center;', sans-serif;
  }
  a {
  color: ${cssVariables.color.blue1};
  text-decoration: none;
  }
`;

export default ({ children }) => (
  <div>
    <GlobalStyle />
    <Helmet
      title="Stimberg Camping"
      meta={[
        {
          name: 'description',
          content:
            'Genießen Sie Natur, Ruhe und erholen sich vom Stress des Alltags. In familiärer Atmosphäre bieten ich Ihnen Dauerstellplätze für Wohnwagen und Mobilheime von 100 bis 300 qm. Jede einzelne Parzelle ist eine kleine grüne Oase.',
        },
        {
          name: 'keywords',
          content:
            'Stimberg Camping, Camping Stimberg, Camping, Datteln, Natur, Wandern, Esel, Reiterhof, Familienurlaub, Spielplätze, Wildfarm',
        },
        { name: 'format-detection', content: 'telephone=no' },
      ]}
      link={[{ rel: 'shortcut icon', type: 'image/png', href: `${icon}` }]}
    />
    {children}
  </div>
);
