export const cssVariables = {
  color: {
    white1: '#fff',
    green1: '#196F3D',
    gray1: '#4A4A4A',
    black1: '#000000',
    blue1: '#3498DB',
  },
  breakPoints: {
    mobile: '530px',
    tablet: '880px',
  },
};
