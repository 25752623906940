import React from 'react';

export default () => {
  return (
    <svg width="20px" height="15px" viewBox="0 0 20 15">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="meta-header"
          transform="translate(-171.000000, -14.000000)"
          fill="#7DCEA0"
          fillRule="nonzero"
        >
          <g id="mail">
            <g transform="translate(171.000000, 13.000000)">
              <g id="email-83" transform="translate(0.000000, 1.000000)">
                <path
                  d="M10.885,8.5475 C10.615,8.6925 10.30875,8.769375 10,8.769375 C9.69125,8.769375 9.385,8.6925 9.114375,8.5475 L0.015,3.675 C0.011875,3.700625 0,3.72375 0,3.75 L0,14.375 C0,14.720625 0.28,15 0.625,15 L19.375,15 C19.72,15 20,14.720625 20,14.375 L20,3.75 C20,3.72375 19.988125,3.70125 19.985,3.675625 L10.885,8.5475 Z"
                  id="Shape"
                ></path>
                <path
                  d="M19.375,0 L0.625,0 C0.28,0 0,0.279375 0,0.625 L0,1.875 C0,2.105625 0.126875,2.3175 0.33,2.42625 L9.705,7.445625 C9.796875,7.495 9.89875,7.519375 10,7.519375 C10.10125,7.519375 10.203125,7.495 10.295,7.445625 L19.67,2.42625 C19.873125,2.3175 20,2.105625 20,1.875 L20,0.625 C20,0.279375 19.72,0 19.375,0 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
