import React from 'react';
import styled, { css } from 'styled-components';
import PhoneSvg from '../Svg/PhoneSvg';
import EmailSvg from '../Svg/EmailSvg';
import LogoSvg from '../images/logo.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { cssVariables } from '../config';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: ${cssVariables.color.white1};
  z-index: 1000;
  box-shadow: 0 1px 5px 0 rgba(25, 111, 61, 0.33),
    0 1px 12px 0 rgba(25, 111, 61, 0.24);

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    height: 40px;
  }
`;

const InnerWrapper = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  display: flex;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    flex-direction: row;
  }

  ${(props) =>
    props.right &&
    css`
      @media (max-width: ${cssVariables.breakPoints.tablet}) {
        display: none;
      }
    `}
`;

const LogoContainer = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    position: absolute;
    left: 50%;
    top: 10px;
    margin-right: 0;
    transform: translateX(-50%);
    img {
      width: 100%;
    }
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 0 10px 0;

  &:last-child {
    margin: 0;
  }

  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    margin: 0;
    height: 40px;

    &:first-child {
      margin-right: 30px;
    }
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${cssVariables.color.green1};
    font-size: 12px;
    border-bottom: 1px solid transparent;
    transition: all ease-in-out 320ms;

    &:hover {
      border-color: ${cssVariables.color.green1};
    }

    @media (min-width: ${cssVariables.breakPoints.tablet}) {
      font-size: 14px;
    }

    &.active {
      border-bottom: 1px solid ${cssVariables.color.green1};
    }
  }

  svg {
    margin-right: 15px;
  }
`;

const Header = (props) => (
  <>
    <div id="header" />
    <Wrapper>
      <InnerWrapper>
        <LogoContainer>
          {props.toIndex ? (
            <Link to="/">
              <img src={LogoSvg} alt="Logo Icon" />
            </Link>
          ) : (
            <AnchorLink href="#header">
              <img src={LogoSvg} alt="Logo Icon" />
            </AnchorLink>
          )}
        </LogoContainer>
        <MenuContainer>
          <Container left>
            <ContactItem>
              <PhoneSvg />
              <a href="tel:+49236332371">+49 2363 32371</a>
            </ContactItem>
            <ContactItem>
              <EmailSvg />
              <a href="mailto:info@stimberg-camping.de">
                info@stimberg-camping.de
              </a>
            </ContactItem>
          </Container>
          <Container right>
            <ContactItem>
              <AnchorLink offset="150" href="#footer">
                Impressum
              </AnchorLink>
            </ContactItem>
            <ContactItem>
              <Link to="/datenschutz" activeClassName="active">
                Datenschutz
              </Link>
            </ContactItem>
          </Container>
        </MenuContainer>
      </InnerWrapper>
    </Wrapper>
  </>
);

export default Header;
