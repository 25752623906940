import React from 'react';

export default () => {
  return (
    <svg width="82px" height="82px" viewBox="0 0 82 82" version="1.1">
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-679.000000, -8388.000000)">
          <g id="Footer" transform="translate(-8.000000, 7452.000000)">
            <g id="Group-5" transform="translate(8.000000, 937.000000)">
              <g id="Group-2" transform="translate(680.000000, 0.000000)">
                <circle
                  id="Oval"
                  stroke="#000000"
                  fill="#FFFFFF"
                  cx="40"
                  cy="40"
                  r="40"
                ></circle>
                <g
                  id="strong-up"
                  transform="translate(29.000000, 17.000000)"
                  fill="#196F3D"
                >
                  <path
                    d="M14,22 L8,22 C7.4,22 7,22.4 7,23 C7,23.6 7.4,24 8,24 L14,24 C14.6,24 15,23.6 15,23 C15,22.4 14.6,22 14,22 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M11,0.4 L0.9,13 L7,13 L7,19 C7,19.6 7.4,20 8,20 L14,20 C14.6,20 15,19.6 15,19 L15,13 L21.1,13 L11,0.4 Z"
                    id="Path"
                  ></path>
                </g>
                <text
                  id="nach-oben"
                  fontFamily="FiraSans-Medium, Fira-Sans"
                  fontSize="12"
                  fontWeight="400"
                  fill="#196F3D"
                >
                  <tspan x="12" y="57">
                    nach oben
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
