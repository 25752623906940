import React from 'react';

export default () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="meta-header"
          transform="translate(-20.000000, -11.000000)"
          fill="#7DCEA0"
          fillRule="nonzero"
        >
          <g id="phone">
            <g transform="translate(20.000000, 11.000000)">
              <g id="phone-2">
                <path
                  d="M16.8181818,20 C7.54454545,20 0,12.4554545 0,3.18181818 C0,3.06136364 0.0477272727,2.94545455 0.133181818,2.86045455 L2.86045455,0.133181818 C3.03818182,-0.0445454545 3.32545455,-0.0445454545 3.50318182,0.133181818 L8.04863636,4.67863636 C8.22636364,4.85636364 8.22636364,5.14363636 8.04863636,5.32136364 L5.64272727,7.72727273 L12.2727273,14.3572727 L14.6786364,11.9513636 C14.8563636,11.7736364 15.1436364,11.7736364 15.3213636,11.9513636 L19.8668182,16.4968182 C20.0445455,16.6745455 20.0445455,16.9618182 19.8668182,17.1395455 L17.1395455,19.8668182 C17.0545455,19.9522727 16.9386364,20 16.8181818,20 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
