import React from 'react';
import styled from 'styled-components';
import HeaderLogoSvg from '../images/header-logo.svg';
import HeaderBanner from '../images/header.jpg';

// contains the Background Image in the Head area
const Container = styled.div`
  height: 500px;
  position: relative;
`;

// contains the Banner Logo
const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover; object-position: center;', sans-serif;
  object-fit: cover;
  object-position: center;
`;

export default () => (
  <Container>
    <LogoContainer>
      <img src={HeaderLogoSvg} alt="Stimberg Camping Logo" />
    </LogoContainer>
    <BackgroundImage src={HeaderBanner} alt="Bild einer Wiese" />
  </Container>
);
