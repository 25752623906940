import React from 'react';
import styled, { css } from 'styled-components';
import portrait from '../images/portrait.png';
import { cssVariables } from '../config';

const Wrapper = styled.div`
  margin: 150px 0 80px 0;
  color: #4a4a4a;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    display: flex;
    flex-direction: row;
  }
  p {
    margin: 0 0 1em;
  }
  h3 {
    margin: 0 0 1em;
    color: #196f3d;
    font-size: 24px;
    text-align: center;
    font-family: pacifico, sans-serif;
  }
  h4 {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    font-family: fira-sans-medium, sans-serif;
  }
  a {
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all ease-in-out 320ms;
    color: #4a4a4a;
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }
  small {
    margin-top: 1em;
    font-size: 12px;
    line-height: 16px;

    @media (min-width: ${cssVariables.breakPoints.tablet}) {
      max-width: 80%;
    }
  }
`;

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  width: auto;
  justify-content: center;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    width: 50%;
    padding: 0 60px;
    justify-content: flex-end;
  }
  ${(props) =>
    props.right &&
    css`
      text-align: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        text-align: center;
      }
      @media (min-width: ${cssVariables.breakPoints.tablet}) {
        justify-content: flex-start;
        text-align: left;
        h3,
        small {
          text-align: left;
        }
      }
    `}
`;

const Contact = styled.div`
  box-sizing: border-box;
  position: relative;
  background-color: #e5f5ec;
  width: 380px;
  padding: 70px 30px 30px 30px;
  border: 1px solid #196f3d;
  border-radius: 15px;
  text-align: center;
  margin: 0;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -65px;
  left: calc(50% - 65px);
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${cssVariables.color.green1};
`;

const Footer = () => (
  <Wrapper id="footer">
    <Container>
      <Contact>
        <IconContainer>
          <img src={portrait} alt="" />
        </IconContainer>
        <h3>Kontakt</h3>
        <p>Sandra Stimberg</p>
        <h4>Anschrift</h4>
        <p>
          Recklinghäuser Straße 140
          <br />
          45711 Datteln
        </p>
        <h4>Telefon</h4>
        <p>
          <a href="tel:+49236332371">+49 2363 32371</a>
        </p>
        <h4>Telefax</h4>
        <p>Fax:+49 2363 357437</p>
        <h4>Email</h4>
        <a href="mailto:info@stimberg-camping.de">info@stimberg-camping.de</a>
      </Contact>
    </Container>
    <Container right>
      <h3>Impressum</h3>
      <h4>Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG):</h4>
      <small>
        Sandra Stimberg wird nicht an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle im Sinne des VSBG teilnehmen und ist
        hierzu auch nicht verpflichtet .
      </small>
    </Container>
  </Wrapper>
);

export default Footer;
