import firaSansRegular from './firasans-regular-webfont.woff';
import firaSansMedium from './firasans-medium-webfont.woff';
import firaSansBold from './firasans-bold-webfont.woff';
import firaSansItalic from './firasans-italic-webfont.woff';
import firaSanLight from './firasans-light-webfont.woff';
import pacificoRegular from './pacifico-regular-webfont.woff';

export default {
  firaSansRegular,
  firaSansMedium,
  firaSansBold,
  firaSansItalic,
  firaSanLight,
  pacificoRegular,
};
