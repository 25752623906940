import React from 'react';
import styled from 'styled-components';
import TopButton from '../Svg/TopButtonSvg';
import { cssVariables } from '../config';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  background-color: ${cssVariables.color.black1};
  position: relative;
`;
const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px;
  color: ${cssVariables.color.white1};

  @media (min-width: ${cssVariables.breakPoints.mobile}) {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  p {
    margin: 1em 0;
  }
  p,
  a {
    color: ${cssVariables.color.white1};
    font-family: 'Fara-Sans', sans-serif;
  }
  a {
    transition: all ease-in-out 320ms;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: ${cssVariables.color.white1};
    }

    &.active {
      text-decoration: underline;
    }
  }

  a + a {
    margin-left: 20px;
  }
`;
const LinkContainer = styled.div`
  display: block;

  @media (min-width: ${cssVariables.breakPoints.mobile}) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const ButtonContainer = styled.div`
  right: 20px;
  top: 15px;
  left: auto;
  position: absolute;
  width: 80px;
  a:hover {
    border: none;
  }
  @media (min-width: ${cssVariables.breakPoints.mobile}) {
    top: -55px;
    left: calc(50% - 42px);
  }
`;

const MetaFooter = () => (
  <Wrapper>
    <ButtonContainer>
      <AnchorLink href="#header">
        <TopButton />
      </AnchorLink>
    </ButtonContainer>
    <InnerWrapper>
      <div>
        <p>© Stimberg Camping 2018</p>
      </div>
      <div>
        <LinkContainer>
          <Link to="/" activeClassName="active">
            Start
          </Link>
          <Link to="/datenschutz/" activeClassName="active">
            Datenschutz
          </Link>
        </LinkContainer>
      </div>
    </InnerWrapper>
  </Wrapper>
);

export default MetaFooter;
