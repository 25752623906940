import React from 'react';

const Map = () => (
  <iframe
    title="Map Section"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9897.313981014273!2d7.271609003749114!3d51.6721299085965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8fd82e57d5ec7%3A0xa11d0317cb788b08!2sRecklingh%C3%A4user+Str.+140%2C+45711+Datteln!5e0!3m2!1sde!2sde!4v1544996135619"
    width="100%"
    height={600}
    frameBorder={0}
    style={{ border: '0' }}
    allowFullScreen
  />
);

export default Map;
